<template>
  <div
    ref="skyWrapper"
    :style="slidePosition"
    class="h-100"
    style="width:200%;"
  >
    <canvas ref="sky" id="sky"></canvas>
  </div>
</template>

<script>
import { slidePositionParallax } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionParallax(this.globalUnits, this.slideIndex)
    }
  },
  mounted() {
    this.drawing()
  },
  methods: {
    drawing() {
      //const c = document.getElementById("sky")
      const c = this.$refs.sky
      const ctx = c.getContext("2d")
      const xMax = (c.width = this.$refs.skyWrapper.scrollWidth)
      const yMax = (c.height = this.$refs.skyWrapper.scrollHeight)

      /* const xMax = c.scrollWidth
      const yMax = c.scrollHeight */

      const min = window.screen.availWidth / 1000
      const max = window.screen.availWidth / 800

      const hmTimes = Math.round((xMax + yMax) / 50)

      for (let i = 0; i <= hmTimes; i++) {
        const randomX = Math.floor(Math.random() * xMax + 1)
        const randomY = Math.floor(Math.random() * yMax + 1)
        //const randomSize = Math.floor(Math.random() * 2 + 1)
        const randomSize = Math.random() * (max - min) + min
        const randomOpacityOne = Math.floor(Math.random() * 9 + 1)
        const randomOpacityTwo = Math.floor(Math.random() * 9 + 1)
        const randomHue = Math.floor(Math.random() * 360 + 1)
        if (randomSize > 1) {
          ctx.shadowBlur = Math.floor(Math.random() * 2 + 2)
          ctx.shadowColor = "white"
        }
        ctx.beginPath()
        ctx.arc(randomX, randomY, randomSize, 0, 2 * Math.PI)
        ctx.fillStyle =
          "hsla(" +
          randomHue +
          ", 40%, 80%, ." +
          randomOpacityOne +
          randomOpacityTwo +
          ")"
        ctx.fill()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$star-color-A: white;
$star-color-B: rgba(255, 255, 255, 0.1);
#sky {
  transition: all 3s ease;
  transform: translateZ(0);
}
</style>
